import { Box, Button, Modal, Paper } from '@mui/material'
import React from 'react'

type ChangeUnitModalProps = {
  isOpen: boolean
  onClose: () => void
  targetUnit: () => void
}

const ChangeUnitModal = (props: ChangeUnitModalProps) => {
  const { isOpen, onClose, targetUnit } = props
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '35%',
          left: '50%',
          transform: 'translate(-50%)',
          padding: '48px',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="20px"
        >
          Your progress will be lost, do you want to continue?
          <Box display="flex" flexDirection="row" gap="20px">
            <Button variant="secondary" size="small" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              size="small"
              onClick={() => {
                targetUnit()
                onClose()
              }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  )
}

export default ChangeUnitModal
