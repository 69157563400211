import {
  Core_PropertyQuestionnaireCompletedTaskResolutionDataPerUnitInput,
  Core_PropertyQuestionnaireUtility,
  Core_PropertyQuestionnaireStakeholder,
  Core_OrderV2TaskStatus,
  CreateAddressDocumentInput,
  Core_PropertyQuestionnairecompletedTaskResolutionDataPerPropertyInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { navigate } from 'gatsby'
import { ORDERS_URL } from '../../../constants'
import {
  PropertyQuestionnaireData,
  PropertyQuestionnaireFunctions,
  QuestionnaireAddressDocumentType,
} from './propertyQuestionnaireTypes'

const uploadDocument = async (
  documentType: string,
  file: File,
  idx: number,
  addressUuid: string,
  createAddressDocument: (params: {
    variables: {
      createAddressDocumentInput: CreateAddressDocumentInput
    }
  }) => Promise<any>
) => {
  const timestamp = new Date()
  const fileExtension = file.name.split('.').pop()
  const createAddressDocumentInput = {
    addressUuid,
    name: `${file.name}-${timestamp.getTime()}-${idx}.${fileExtension}`,
    type: documentType,
    files: [file],
  }
  const result = await createAddressDocument({
    variables: {
      createAddressDocumentInput,
    },
  })
  return {
    key: documentType,
    uuid: result?.data?.createAddressDocument?.addressDocuments[0]
      ?.documentUuid,
  }
}

export const flattenPropertyQuestionnaireUnitData = async (
  flowData: PropertyQuestionnaireData,
  functions: PropertyQuestionnaireFunctions
) => {
  const {
    leaseAndProperty,
    hoaAndUtilities,
    orderUuid,
    taskUuid,
    addressUuid,
    oneTimeLink,
    unauthed,
  } = flowData
  const { updatePropertyQuestionnaireTask, createAddressDocument } = functions

  const uploadPromises: Promise<{ key: string; uuid: string }>[] = []
  if (leaseAndProperty?.hasPropertyManager) {
    const propertyManagementAgreements =
      (leaseAndProperty.propertyManagementAgreement as File[]) || []
    propertyManagementAgreements.forEach((file, idx) => {
      uploadPromises.push(
        uploadDocument(
          QuestionnaireAddressDocumentType.PROPERTY_MANAGEMENT_AGREEMENT,
          file,
          idx,
          addressUuid,
          createAddressDocument
        )
      )
    })
  }

  const fileUploadResults: { key: string; uuid: string }[] = await Promise.all(
    uploadPromises
  )

  const propertyQuestionnaireResolutionDataUnit: Core_PropertyQuestionnaireCompletedTaskResolutionDataPerUnitInput =
    {
      addressUuid,

      hasLease: leaseAndProperty.hasLeaseInPlace === 'true',
      leaseInfo:
        leaseAndProperty.hasLeaseInPlace === 'true'
          ? {
              leaseStartDate: leaseAndProperty.leaseStartDate,
              leaseEndDate: leaseAndProperty.leaseEndDate,
              tenantInfo: leaseAndProperty.tenantInfo.map((info: any) => ({
                name: info.tenantName,
                email: info.tenantEmail,
                phoneNumber: info.tenantPhoneNumber,
              })),
            }
          : undefined,

      hasPropertyManager: leaseAndProperty.hasPropertyManager === 'true',
      propertyManager:
        leaseAndProperty.hasPropertyManager === 'true'
          ? {
              contactPropertyManager:
                leaseAndProperty.canContactPropertyManager === 'true',
              rentCollector: leaseAndProperty.rentCollector,
              securityDepositHolder: leaseAndProperty.securityDepositHolder,
              propertyManagementAgreement: fileUploadResults
                .filter(
                  (fileUpload) =>
                    fileUpload.key ===
                    QuestionnaireAddressDocumentType.PROPERTY_MANAGEMENT_AGREEMENT
                )
                .map((fileUpload) => fileUpload.uuid),
            }
          : undefined,

      hasHoa: hoaAndUtilities.hasHoa === 'true',
      hoa:
        hoaAndUtilities.hasHoa === 'true'
          ? {
              coversUtilities: hoaAndUtilities.hoaCoversUtilites === 'true',
              coveredUtilites:
                hoaAndUtilities.hoaCoversUtilites === 'true'
                  ? [
                      hoaAndUtilities.hoaCoversTrash
                        ? Core_PropertyQuestionnaireUtility.PropertyQuestionnaireUtilityTrash
                        : undefined,
                      hoaAndUtilities.hoaCoversWater
                        ? Core_PropertyQuestionnaireUtility.PropertyQuestionnaireUtilityWater
                        : undefined,
                      hoaAndUtilities.hoaCoversElectricity
                        ? Core_PropertyQuestionnaireUtility.PropertyQuestionnaireUtilityElectricity
                        : undefined,
                      hoaAndUtilities.hoaCoversGas
                        ? Core_PropertyQuestionnaireUtility.PropertyQuestionnaireUtilityGas
                        : undefined,
                    ].filter((option) => option !== undefined)
                  : undefined,
              name: hoaAndUtilities.hoaName,
              email: hoaAndUtilities.hoaEmail,
              accountNumber: hoaAndUtilities.hoaAccount,
              hasManagementCompany: hoaAndUtilities.hasHoaManagement === 'true',
              managementCompany:
                hoaAndUtilities.hasHoaManagement === 'true'
                  ? {
                      name: hoaAndUtilities.hoaManagementName,
                      email: hoaAndUtilities.hoaManagementEmail,
                      phone: hoaAndUtilities.hoaManagementPhone,
                    }
                  : undefined,
            }
          : undefined,

      logistics: {
        hasWasherDryer: leaseAndProperty.hasWasherDryer === 'true',
        washerDryerOwner: leaseAndProperty.washerDryerOwner || undefined,
        rentalServiceStartDate: leaseAndProperty.rentalServiceDate,
        garageDoorCode: leaseAndProperty.garageCode || undefined,
        mailboxLocation: leaseAndProperty.mailboxDescription || undefined,
        keyHolder: [
          leaseAndProperty.pmHasKeys
            ? Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderPropertyManager
            : undefined,
          leaseAndProperty.ownerHasKeys
            ? Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOwner
            : undefined,
          leaseAndProperty.otherHasKeys
            ? Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOther
            : undefined,
        ].filter((option) => option !== undefined),
        otherKeyholderDescription: leaseAndProperty.otherHasKeysNames,
      },

      utilities: {
        electricityCompanyName: hoaAndUtilities.electricityProviderName,
        electricityCompanyPhone: hoaAndUtilities.electricityProviderPhoneNumber,
        electricityCompanyStakeholder:
          hoaAndUtilities.electricityBillStakeholder,

        waterCompanyName: hoaAndUtilities.waterProviderName,
        waterCompanyPhone: hoaAndUtilities.waterProviderPhoneNumber,
        waterCompanyStakeholder: hoaAndUtilities.waterBillStakeholder,

        trashCompanyName: hoaAndUtilities.trashProviderName,
        trashCompanyPhone: hoaAndUtilities.trashProviderPhoneNumber,
        trashCompanyStakeholder: hoaAndUtilities.trashBillStakeholder,

        gasCompanyName: hoaAndUtilities.gasProviderName,
        gasCompanyPhone: hoaAndUtilities.gasProviderPhoneNumber,
        gasCompanyStakeholder: hoaAndUtilities.gasBillStakeholder,
      },
    }
  await updatePropertyQuestionnaireTask({
    variables: {
      input: {
        link: unauthed ? oneTimeLink : undefined,
        orderUuid,
        taskData: {
          taskUuid,
          status: Core_OrderV2TaskStatus.OrderV2TaskStatusStarted,
          resolutionData: {
            data: {
              propertyQuestionnaireCompletedTaskResolutionData: {
                perUnitData: [propertyQuestionnaireResolutionDataUnit],
              },
            },
          },
        },
      },
    },
  })
  // figure out how to refetch
  window.location.reload()
}

export const flattenPropertyQuestionnaireMiscData = async (
  flowData: any,
  functions: PropertyQuestionnaireFunctions
) => {
  const { miscellaneousInfo, orderUuid, taskUuid, addressUuid } = flowData
  const { updatePropertyQuestionnaireTask, createAddressDocument } = functions
  const uploadPromises: Promise<{ key: string; uuid: string }>[] = []
  const depreciationSchedules =
    (miscellaneousInfo.depreciationSchedule as File[]) || []
  depreciationSchedules.forEach((file, idx) => {
    uploadPromises.push(
      uploadDocument(
        QuestionnaireAddressDocumentType.DEPRECIATION_SCHEDULE,
        file,
        idx,
        addressUuid,
        createAddressDocument
      )
    )
  })

  if (miscellaneousInfo.specialFireDistrict === 'true') {
    const fireDuesBills =
      (miscellaneousInfo.specialFireDisctrictDuesBill as File[]) || []
    fireDuesBills.forEach((file, idx) => {
      uploadPromises.push(
        uploadDocument(
          QuestionnaireAddressDocumentType.FIRE_DUES_BILL,
          file,
          idx,
          addressUuid,
          createAddressDocument
        )
      )
    })
  }

  if (miscellaneousInfo.solarPanelsInstalled === 'true') {
    const solarPanelsStatement =
      (miscellaneousInfo.specialFireDisctrictDuesBill as File[]) || []
    solarPanelsStatement.forEach((file, idx) => {
      uploadPromises.push(
        uploadDocument(
          QuestionnaireAddressDocumentType.SOLAR_PANELS_ACCOUNT_STATEMENT,
          file,
          idx,
          addressUuid,
          createAddressDocument
        )
      )
    })
  }

  if (miscellaneousInfo.specialAssessmentLiensOrImprovementBonds === 'true') {
    const liensOrBondsDocuments =
      (miscellaneousInfo.specialAssessmentLiensOrImprovementBondsDocuments as File[]) ||
      []
    liensOrBondsDocuments.forEach((file, idx) => {
      uploadPromises.push(
        uploadDocument(
          QuestionnaireAddressDocumentType.LIEN_OR_BOND,
          file,
          idx,
          addressUuid,
          createAddressDocument
        )
      )
    })
  }

  if (miscellaneousInfo.hasSurvey === 'true') {
    const surveyDocuments = (miscellaneousInfo.surveyDocuments as File[]) || []
    surveyDocuments.forEach((file, idx) => {
      uploadPromises.push(
        uploadDocument(
          QuestionnaireAddressDocumentType.SURVEY,
          file,
          idx,
          addressUuid,
          createAddressDocument
        )
      )
    })
  }

  if (miscellaneousInfo.ownersPolicyTitleInsurance === 'true') {
    const ownersPolicyTitleInsuranceDocuments =
      (miscellaneousInfo.ownersPolicyTitleInsuranceDocuments as File[]) || []
    ownersPolicyTitleInsuranceDocuments.forEach((file, idx) => {
      uploadPromises.push(
        uploadDocument(
          QuestionnaireAddressDocumentType.OWNERS_TITLE_INSURANCE,
          file,
          idx,
          addressUuid,
          createAddressDocument
        )
      )
    })
  }

  const fileUploadResults: { key: string; uuid: string }[] = await Promise.all(
    uploadPromises
  )

  const propertyQuestionnaireResolutionDataProperty: Core_PropertyQuestionnairecompletedTaskResolutionDataPerPropertyInput =
    {
      costBasis: {
        year: miscellaneousInfo.costBasisYear,
        month: miscellaneousInfo.costBasisMonth,
        costBasisAmount: miscellaneousInfo.costBasisAmount || undefined,
        depreciationSchedule: fileUploadResults
          .filter(
            (fileUpload) =>
              fileUpload.key ===
              QuestionnaireAddressDocumentType.DEPRECIATION_SCHEDULE
          )
          .map((fileUpload) => fileUpload.uuid),
      },

      hasCashOutRefinance: miscellaneousInfo.cashOutRefinance === 'true',
      cashOutRefinanceInfo:
        miscellaneousInfo.cashOutRefinance === 'true'
          ? {
              cashOutRefinanceDescription:
                miscellaneousInfo.cashOutRefinanceDescription,
            }
          : undefined,

      hasFireDues: miscellaneousInfo.specialFireDistrict === 'true',
      fireDuesInfo:
        miscellaneousInfo.specialFireDistrict === 'true'
          ? {
              fireDuesAmount: miscellaneousInfo.specialFireDistrictDuesAmount,
              fireDuesBill: fileUploadResults
                .filter(
                  (fileUpload) =>
                    fileUpload.key ===
                    QuestionnaireAddressDocumentType.FIRE_DUES_BILL
                )
                .map((fileUpload) => fileUpload.uuid),
            }
          : undefined,

      hasSolarPanels: miscellaneousInfo.solarPanelsInstalled === 'true',
      solarPanelsInfo:
        miscellaneousInfo.solarPanelsInstalled === 'true'
          ? {
              solarPanelsOwned: miscellaneousInfo.solarPanelsOwned === 'true',
              solarPanelsAccountStatement: fileUploadResults
                .filter(
                  (fileUpload) =>
                    fileUpload.key ===
                    QuestionnaireAddressDocumentType.SOLAR_PANELS_ACCOUNT_STATEMENT
                )
                .map((fileUpload) => fileUpload.uuid),
            }
          : undefined,

      hasLiensOrBonds:
        miscellaneousInfo.specialAssessmentLiensOrImprovementBonds === 'true',
      lienOrBondInfo:
        miscellaneousInfo.specialAssessmentLiensOrImprovementBonds === 'true'
          ? {
              numberLiensOrBonds:
                miscellaneousInfo.specialAssessmentLiensOrImprovementBondsAmount,
              lienOrBondDocuments: fileUploadResults
                .filter(
                  (fileUpload) =>
                    fileUpload.key ===
                    QuestionnaireAddressDocumentType.LIEN_OR_BOND
                )
                .map((fileUpload) => fileUpload.uuid),
              lienOrBondCompanies: miscellaneousInfo.lienOrBondCompanyInfo.map(
                (lienOrBondInfo: any) => ({
                  companyName:
                    lienOrBondInfo.specialAssessmentLiensOrImprovementBondsCompanyName,
                  companyEmail:
                    lienOrBondInfo.specialAssessmentLiensOrImprovementBondsCompanyEmail,
                  companyPhone:
                    lienOrBondInfo.specialAssessmentLiensOrImprovementBondsCompanyPhone,
                  companyAddress:
                    lienOrBondInfo.specialAssessmentLiensOrImprovementBondsCompanyAddress,
                  accountNumber:
                    lienOrBondInfo.specialAssessmentLiensOrImprovementBondsCompanyAccountNumber,
                })
              ),
            }
          : undefined,

      hasSurvey: miscellaneousInfo.hasSurvey === 'true',
      surveyInfo:
        miscellaneousInfo.hasSurvey === 'true'
          ? {
              surveyDocuments: fileUploadResults
                .filter(
                  (fileUpload) =>
                    fileUpload.key === QuestionnaireAddressDocumentType.SURVEY
                )
                .map((fileUpload) => fileUpload.uuid),
              improvementsSinceSurvey:
                miscellaneousInfo.hasSurveyImprovements === 'true',
              improvementsSinceSurveyDescription:
                miscellaneousInfo.hasSurveyImprovements === 'true'
                  ? miscellaneousInfo.surveyImprovements
                  : undefined,
            }
          : undefined,

      hasOwnerTitlePolicy:
        miscellaneousInfo.ownersPolicyTitleInsurance === 'true',
      ownerTitlePolicyInfo:
        miscellaneousInfo.ownersPolicyTitleInsurance === 'true'
          ? {
              ownerTitlePolicyDocuments: fileUploadResults
                .filter(
                  (fileUpload) =>
                    fileUpload.key ===
                    QuestionnaireAddressDocumentType.OWNERS_TITLE_INSURANCE
                )
                .map((fileUpload) => fileUpload.uuid),
            }
          : undefined,

      hasMortgage: miscellaneousInfo.hasMortgage === 'true',
      mortgageInfo:
        miscellaneousInfo.hasMortgage === 'true'
          ? {
              lenderInfo: miscellaneousInfo.mortgageLenderInfo.map(
                (lenderInfo: any) => ({
                  lenderName: lenderInfo.lenderName,
                  lenderPhone: lenderInfo.lenderPhoneNumber,
                  loanNumber: lenderInfo.loanNumber,
                  loanAmount: lenderInfo.loanAmount,
                  lastPaymentDate: lenderInfo.mortgageLastPaymentDate,
                  ssn: lenderInfo.loanSsn,
                  additionalInfo: lenderInfo.lenderAdditionalInfo || undefined,
                })
              ),
            }
          : undefined,

      usedAsPrimaryResidence:
        miscellaneousInfo.usedAsPrimaryResidence === 'true',
      soldOrExchangeAnotherPrincipalResidence:
        miscellaneousInfo.soldOrExchangeAnotherPrincipalResidence === 'true',
      businessOrRentalAfterDate:
        miscellaneousInfo.businessOrRentalAfterDate === 'true',
      saleOrExchangeAmountDetails:
        miscellaneousInfo.saleOrExchangeAmountDetails === 'true',
      contributed1031: miscellaneousInfo.contributed1031 === 'true',
      acquired1031: miscellaneousInfo.acquired1031 === 'true',

      canClaimPossession:
        miscellaneousInfo.otherPartiesCanClaimPossession === 'true',
      canClaimPossessionDescription:
        miscellaneousInfo.otherPartiesCanClaimPossessionDescription ||
        undefined,

      pendingLitigation: miscellaneousInfo.pendingLitigations === 'true',
      pendingLitigationDescription:
        miscellaneousInfo.pendingLitigationsDescription || undefined,

      unpaidAlterations:
        miscellaneousInfo.unpaidRepairsOrAlterations === 'true',
      unpaidAlterationsDescription:
        miscellaneousInfo.unpaidRepairsOrAlterationsDescription || undefined,

      futureAssessmentsOrTaxes:
        miscellaneousInfo.futureAssessmentsOrTaxes === 'true',
      futureAssessmentsOrTaxesDescription:
        miscellaneousInfo.futureAssessmentsOrTaxesDescription || undefined,

      uccFinancingStatements:
        miscellaneousInfo.uccFinancingStatements === 'true',
      uccFinancingStatementsDescription:
        miscellaneousInfo.uccFinancingStatementsDescription || undefined,

      hasEncumberances: miscellaneousInfo.otherEncumberances === 'true',
      encumberancesDescription:
        miscellaneousInfo.otherEncumberancesDescription || undefined,

      pendingBankruptcy: miscellaneousInfo.pendingBankruptcy === 'true',
      pendingBankruptcyDescription:
        miscellaneousInfo.pendingBankruptcyDescription || undefined,

      pendingProjects: miscellaneousInfo.outstandingProjects === 'true',
      pendingProjectsDescription:
        miscellaneousInfo.outstandingProjectsDescription || undefined,
    }
  await updatePropertyQuestionnaireTask({
    variables: {
      input: {
        orderUuid,
        taskData: {
          taskUuid,
          status: Core_OrderV2TaskStatus.OrderV2TaskStatusCompleted,
          resolutionData: {
            data: {
              propertyQuestionnaireCompletedTaskResolutionData: {
                perPropertyData: propertyQuestionnaireResolutionDataProperty,
              },
            },
          },
        },
      },
    },
  })
  navigate(`${ORDERS_URL}/${orderUuid}`)
}
