import {
  Core_PropertyQuestionnaireStakeholder,
  Core_UpdateOrderTaskRequestInput,
  Core_UpdateOrderTaskUnauthedRequestInput,
  CreateAddressDocumentInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'

type StakeholderChoices =
  | Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOwner
  | Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderPropertyManager
  | Core_PropertyQuestionnaireStakeholder.PropertyQuestionnaireStakeholderOther

type LeaseAndPropertyStepData = {
  hasLeaseInPlace: 'false' | 'true'
  leaseStartDate: Date
  leaseEndDate: Date
  tenantInfo: {
    tenantName: string
    tenantEmail: string
    tenantPhoneNumber: string
  }[]
  hasPropertyManager: 'false' | 'true'
  canContactPropertyManager: 'false' | 'true'
  rentCollector: StakeholderChoices
  securityDepositHolder: StakeholderChoices
  notAlreadyUploaded: boolean
  propertyManagementAgreement: File[]

  rentalServiceDate: Date
  hasWasherDryer: 'false' | 'true'
  washerDryerOwner: StakeholderChoices
  garageCode: string
  mailboxDescription: string
  ownerHasKeys: boolean
  pmHasKeys: boolean
  otherHasKeys: boolean
  otherHasKeysNames: string
}

type HoaAndUtilitiesStepData = {
  hasHoa: 'false' | 'true'
  hoaName: string
  hoaEmail: string
  hoaAccount: string
  hasHoaManagement: 'false' | 'true'
  hoaManagementName: string
  hoaManagementEmail: string
  hoaManagementPhone: string
  hoaCoversUtilites: 'false' | 'true'
  hoaCoversTrash: 'false' | 'true'
  hoaCoversWater: 'false' | 'true'
  hoaCoversElectricity: 'false' | 'true'
  hoaCoversGas: 'false' | 'true'
  trashProviderName: string
  trashProviderPhoneNumber: string
  trashBillStakeholder: StakeholderChoices
  waterProviderName: string
  waterProviderPhoneNumber: string
  waterBillStakeholder: StakeholderChoices
  electricityProviderName: string
  electricityProviderPhoneNumber: string
  electricityBillStakeholder: StakeholderChoices
  gasProviderName: string
  gasProviderPhoneNumber: string
  gasBillStakeholder: StakeholderChoices
}

type QuestionnaireMiscStepData = {
  costBasisYear: string
  costBasisMonth: string
  knowsCostBasis: 'false' | 'true'
  costBasisAmount: string
  depreciationSchedule: File[]
  cashOutRefinance: 'false' | 'true'
  cashOutRefinancingDescription: string
  specialFireDistrict: 'false' | 'true'
  specialFireDistrictDuesAmount: string
  specialFireDisctrictDuesBill: File[]
  solarPanelsInstalled: 'false' | 'true'
  solarPanelsOwned: 'false' | 'true'
  solarPanelAccountStatement: File[]
  specialAssessmentLiensOrImprovementBonds: 'false' | 'true'
  specialAssessmentLiensOrImprovementBondsAmount: string
  specialAssessmentLiensOrImprovementBondsDocuments: File[]
  lienOrBondCompanyInfo: {
    specialAssessmentLiensOrImprovementBondsCompany: string
    specialAssessmentLiensOrImprovementBondsCompanyEmail: string
    specialAssessmentLiensOrImprovementBondsCompanyPhone: string
    specialAssessmentLiensOrImprovementBondsCompanyAddress: string
    specialAssessmentLiensOrImprovementBondsCompanyAccountNumber: string
  }[]
  specialAssessmentLiensOrImprovementBondsConsent: boolean
  hasSurvey: 'false' | 'true'
  surveyDocuments: File[]
  hasSurveyImprovemenets: 'false' | 'true'
  ownersPolicyTitleInsurance: 'false' | 'true'
  ownersPolicyTitleInsuranceDocuments: File[]

  hasMortgage: 'false' | 'true'
  mortgageLenderInfo: {
    lenderName: string
    lenderPhoneNumber: string
    loanNumber: string
    loanAmount: string
    mortgageLastPaymentDate: Date
    loanSsn: string
    lenderAdditionalInfo: string
  }[]
  mortgageInformationAuthorization: boolean
  usedAsPrimaryResidence: 'false' | 'true'
  soldOrExchangeAnotherPrincipalResidence: 'false' | 'true'
  businessOrRentalAfterDate: 'false' | 'true'
  saleOrExchangeAmountDetails: 'false' | 'true'
  contributed1031: 'false' | 'true'
  acquired1031: 'false' | 'true'
  otherPartiesCanClaimPossession: 'false' | 'true'
  otherPartiesCanClaimPossessionDetails: string
  pendingLitigation: 'false' | 'true'
  pendingLitigationsDescription: string
  unpaidRepairsOrAlterations: 'false' | 'true'
  unpaidRepairsOrAlterationsDescription: string
  futureAssessmentsOrTaxes: 'false' | 'true'
  uccFinancingStatements: 'false' | 'true'
  uccFinancingStatementsDescription: string
  otherEncumberances: 'false' | 'true'
  otherEncumberancesDescription: string
  pendingBankruptcy: 'false' | 'true'
  pendingBankruptcyDescription: string
  outstandingProjects: 'false' | 'true'
  outstandingProjectsDescription: string
}

export type PropertyQuestionnaireData = {
  leaseAndProperty: LeaseAndPropertyStepData
  hoaAndUtilities: HoaAndUtilitiesStepData
  miscallaneousInfo: QuestionnaireMiscStepData

  orderUuid: string
  taskUuid: string
  addressUuid: string
  oneTimeLink: string
  unauthed: boolean
}

export type PropertyQuestionnaireFunctions = {
  updatePropertyQuestionnaireTask: (params: {
    variables: {
      input:
        | Core_UpdateOrderTaskRequestInput
        | Core_UpdateOrderTaskUnauthedRequestInput
    }
  }) => Promise<any>
  createAddressDocument: (params: {
    variables: {
      createAddressDocumentInput: CreateAddressDocumentInput
    }
  }) => Promise<any>
}

export enum QuestionnaireAddressDocumentType {
  DEPRECIATION_SCHEDULE = 'depreciation_schedule',
  PROPERTY_MANAGEMENT_AGREEMENT = 'property_management_agreement',
  FIRE_DUES_BILL = 'fire_dues_bill',
  SOLAR_PANELS_ACCOUNT_STATEMENT = 'solar_panels_account_statement',
  LIEN_OR_BOND = 'lien_or_bond',
  SURVEY = 'survey',
  OWNERS_TITLE_INSURANCE = 'owners_title_insurance',
}
