import React from 'react'
import { Link, LinkProps } from '@mui/material'
import slugify from 'slugify'
import GatsbyLink from 'gatsby-link'
import { UserEventType } from '@flock/utils'
import { flockTheme } from '../../theme'
import { TrackingConfig, useTracking } from '../useTracking'
import { getNodeText } from '../../utils'

export type TrackedLinkProps = LinkProps & {
  trackingConfig?: TrackingConfig
  to?: string
  component?: string
  href?: string
}

const TrackedLink = (props: TrackedLinkProps) => {
  const { onClick, trackingConfig, to, color, href, ...otherProps } = props
  const { track } = useTracking()

  const onClickLink = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    let trackingName = 'link-click'
    if (trackingConfig?.name) {
      trackingName = trackingConfig?.name
    } else if (props.children) {
      const nodeText = getNodeText(props.children)
      if (nodeText) {
        trackingName = slugify(nodeText.toLowerCase())
      }
    }

    track(trackingName, {
      type: 'link',
      href,
      to,
      ...trackingConfig?.properties,
      actionType: UserEventType.LINK_CLICK,
    })
    if (onClick) {
      onClick(event)
    }
  }

  if (to) {
    return (
      // @ts-ignore
      <Link
        component={GatsbyLink}
        {...otherProps}
        onClick={onClickLink}
        to={to}
        activeStyle={{
          ...(flockTheme.components?.MuiLink?.styleOverrides?.root as any),
          color,
        }}
      />
    )
  }

  return <Link {...otherProps} onClick={onClickLink} />
}

TrackedLink.defaultProps = {
  trackingConfig: null,
  to: '',
  href: '',
}

export default TrackedLink
